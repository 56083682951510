import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { VCombobox, VAutocomplete, VSelect } from "vuetify/lib";

@Component({
  inheritAttrs: false,
  components: {
    VCombobox,
    VAutocomplete,
    VSelect,
  },
})
export default class BaseSelect extends Vue {
  @Ref("list")
  private readonly listRef?: {
    focus: () => void;
    blur: () => void;
    activateMenu: () => void;
    validate: () => boolean;
  };
  @Prop({ type: String, default: "select" }) private readonly type!: string;

  @Prop({ type: String, default: "$arrowDropDown" })
  private readonly appendIcon!: string;

  @Prop({ type: Object, default: () => ({}) })
  private readonly menuProps!: Record<string, unknown>;

  private get componentName() {
    if (this.type === "combobox") {
      return "VCombobox";
    }

    if (this.type === "autocomplete") {
      return "VAutocomplete";
    }

    return "VSelect";
  }

  private get menuPropsContentClass() {
    const { contentClass } = this.menuProps;

    return ["base-dropdown-list", contentClass]
      .filter((v) => typeof v === "string" && v)
      .join(" ");
  }

  private get localMenuProps() {
    const menuProps = { ...this.menuProps };

    delete menuProps.contentClass;

    return menuProps;
  }

  private focus() {
    this.listRef?.focus();
  }

  private blur() {
    this.listRef?.blur();
  }

  private activateMenu() {
    this.listRef?.activateMenu();
  }

  private validate() {
    return this.listRef?.validate();
  }
}
