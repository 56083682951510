import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class Dialog extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly value!: boolean;
  @Prop({ type: Boolean, default: true })
  private readonly renderContentAfterShow!: boolean;

  private showedContent = false;
  private showedNavigationDrawer = false;

  private get localListeners() {
    const $listeners = { ...this.$listeners };

    delete $listeners.input;

    return $listeners;
  }

  private toggleNavigationDrawer(showed = !this.showedNavigationDrawer) {
    this.showedNavigationDrawer = showed;
  }

  private mounted() {
    let transitionTimeoutId = 0;

    this.toggleNavigationDrawer(this.value);
    this.showedContent = this.showedNavigationDrawer;

    this.$watch(
      () => {
        return this.value;
      },
      (value) => {
        this.toggleNavigationDrawer(value);
      }
    );

    this.$watch(
      () => {
        return this.showedNavigationDrawer;
      },
      (showedNavigationDrawer) => {
        window.clearTimeout(transitionTimeoutId);

        this.showedContent = true;

        if (!showedNavigationDrawer) {
          transitionTimeoutId = window.setTimeout(() => {
            this.showedContent = !this.renderContentAfterShow;
            this.$emit("input", false);
          }, 300);
        } else {
          this.$emit("input", true);
        }
      }
    );

    this.$once("hook:beforeDestroy", () => {
      window.clearTimeout(transitionTimeoutId);
    });
  }
}
